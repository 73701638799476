<template>
	<div style="height: 100%;">
		<main-table tool>
			<div slot="table" class="df f1 fdc">
				<div class="df fww">
					<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="df jcsb mb5">
							<div class="fs16 fw6 c3">{{status.count || 0}}</div>
						</div>
						<div class="fs14 c6">{{status.name}}</div>
					</div>
					<div class="mb10">
						<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
						<el-button type="primary" size="mini" @click="transfer = { }; transfers_drawer = true" :disabled="!$utils.create($api.URI_MATERIALS_TRANSFERS)">创建调拨</el-button>
					</div>
				</div>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="名称/供应商/编码/备注" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.in_warehouses" placeholder="请选择调入仓库" filterable multiple collapse-tags>
							<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.out_warehouses" placeholder="请选择调出仓库" filterable multiple collapse-tags>
							<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getSuppliers(params)">查询</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="suppliers" :size="theme.size">
						<el-table-column label="创建人" min-width="100" prop="employee.name"></el-table-column>
						<el-table-column label="流水单号" min-width="160" prop="no"></el-table-column>
						<el-table-column label="仓库" min-width="120">
							<template slot-scope="scope">
								<p>调入：{{scope.row.warehouse_in.name}}</p>
								<p>调出：{{scope.row.warehouse_out.name}}</p>
							</template>
						</el-table-column>
						<el-table-column label="物料清单" min-width="80">
							<el-link slot-scope="scope" type="primary" @click="transfer = { ...scope.row}; transfers_drawer = true; getSheets({material_transfer_id: scope.row.id, perPage: 100})">{{scope.row.transfer_materials_count}} 款</el-link>
						</el-table-column>
						<el-table-column label="状态" min-width="80">
							<template slot-scope="scope">
								<el-button type="text" @click="onStatus(scope.row)" v-if="scope.row.status == 1 || scope.row.status == 3">{{statuses[scope.row.status]}}</el-button>
								<span v-else>{{statuses[scope.row.status]}}</span>
							</template>
						</el-table-column>
						<el-table-column label="备注说明" min-width="160" prop="remarks"></el-table-column>
						<template v-if="theme.width >= 1420">
							<el-table-column label="调拨时间" min-width="150" prop="trans_at"></el-table-column>
							<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
							<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
						</template>
						<el-table-column label="操作" width="60">
							<template slot-scope="scope">
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_MATERIALS_TRANSFERS)" v-if="!scope.row.deleted_at">删除</el-button>
								<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_MATERIALS_TRANSFERS)" v-else>恢复</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getSuppliers({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getSuppliers({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<!-- 编辑添加 -->
		<el-dialog width="420px" :title="transfer.id ? '编辑调拨' : '添加调拨'" :visible.sync="create_dialog" @closed="transfer = {}" append-to-body destroy-on-close>
			<el-form ref="transfers_create" style="margin-top: 20px;" label-width="80px" size="mini" :model="transfer" :rules="transfers_rules" v-if="!transfer.id" status-icon>
				<el-form-item label="调出仓库" prop="out_warehouse_id">
					<el-select v-model="transfer.out_warehouse_id" placeholder="请选择调出仓库">
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="调入仓库" prop="in_warehouse_id">
					<el-select v-model="transfer.in_warehouse_id" placeholder="请选择调入仓库">
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="remarks">
					<el-input v-model="transfer.remarks" type="textarea" rows="3" placeholder="请输入备注" maxlength="562" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('transfers_create')">提交创建</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 物料清单 -->
		<el-drawer :size="theme.width <= 640 ? '100%' : '640px'" :title="transfer.no + ' 调拨物料清单'"  :visible.sync="transfers_drawer">
			<div style="padding: 0 10px;">
				<div style="line-height: 1.5; padding: 10px; color: #666; font-size: 14px; background-color: #f9f9f9;">
					<p v-if="transfer.warehouse_out">调出仓库：{{transfer.warehouse_out.name}}</p>
					<p v-if="transfer.warehouse_in">调入仓库：{{transfer.warehouse_in.name}}</p>
					<p v-if="transfer.remarks">备注说明：{{transfer.remarks}}</p>
				</div>
				<el-table :data="sheets" :size="theme.size" @sort-change="sortSheetsChange">
					<el-table-column label="编码" min-width="140" prop="in_code" sortable="custom"></el-table-column>
					<el-table-column label="名称" min-width="140" prop="name"></el-table-column>
					<el-table-column label="数量" min-width="110" v-if="transfer.status == 1 || transfer.status == 3">
						<el-input-number slot-scope="scope" size="mini" v-model="scope.row.amount" @change="(e) => { submitSheets({id: scope.row.id, amount: e}, () => { getSheets({material_transfer_id: transfer.id,  perPage: 100}); }); }" :min="1" :max="scope.row.max_amount || 0" label="数量"></el-input-number>
					</el-table-column>
					<el-table-column label="数量" min-width="110" prop="amount"></el-table-column>
					<el-table-column label="操作" width="60" v-if="transfer.status == 1 || transfer.status == 3">
						<template slot-scope="scope">
							<el-popconfirm title="确定删除这条记录吗？" @confirm="delSheet(scope.row)">
								<el-button slot="reference" type="text">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<el-form ref="create_sheet" style="margin-top: 10px;" :inline="true" :model="sheet" :size="theme.size" v-if="transfer.status == 1 || transfer.status == 3">
					<el-form-item prop="no" :rules="[{ required: true, message: '请输入物料编码', trigger: 'blur' }]">
						<el-input v-model="sheet.no" placeholder="请输入物料编码" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_sheet')">添加物料</el-button>
						<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importSupplies" :action="$api.URI_UPLOADS">
							<el-button type="primary" plain>批量导入</el-button>
						</el-upload>
						<el-button type="text" @click="downloadTemp">下载模板</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import mainTable from '@/components/main-table';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		components: {
			mainTable,
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			table_temp () {
				const thead = ['编码', '数量'];
				const filter = ['material_no', 'amount'];
				return {
					data: [],
					sheet: '物料明细',
					thead,
					filter
				};
			}
		},
		methods: {
			sortSheetsChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = {material_transfer_id: this.transfer.id,  perPage: 100};
					p.order = o;
				this.getSheets(p);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.no+'】调拨吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_TRANSFERS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			async delSheet (r) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_TRANSFERS_SHEETS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getSheets({material_transfer_id: this.transfer.id, perPage: 100});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.no+'】调拨吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_TRANSFERS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'transfers_create':
							this.submitSuppliers(this.transfer);
						break;
						case 'create_sheet':
							this.submitSheets({
								material_transfer_id: this.transfer.id,
								material_no: this.sheet.no,
							}, () => {
								this.getSheets({material_transfer_id: this.transfer.id,  perPage: 100});
								this.sheet = {};
							});
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			async submitSuppliers (data) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_TRANSFERS, data);
				const { code, msg: message, result } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose: () => {
						this.transfer = result;
					}
				});
			},
			async submitSheets (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_TRANSFERS_SHEETS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getSuppliers(this.params);
			},
			async getSuppliers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_TRANSFERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.suppliers = result.data;
				this.warehouses = result.warehouses;
				this.statuses = result.statuses;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getSheets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_TRANSFERS_SHEETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.sheets = result.data;
			},
			onStatus (r) {
				const { id, no, transfer_materials_count, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!transfer_materials_count) return this.$message.error('物料清单为0无法提交审核！');
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">'+ transfer_materials_count + ' 款物料</b>', '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\MaterialTransfer'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.getSuppliers(this.params);
								}
							});
						});
					break;

					default:
						this.open(r);
					break;
				}
			},
			importSupplies ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入调拨物料...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { material_no, amount } = data[i];
							if (!material_no) {
								data[i].status = "提交物料编码不能为空";
								continue;
							}
							if (!amount) {
								data[i].status = "提供数量";
								continue;
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_MATERIALS_TRANSFERS_SHEETS, {...data[i], material_transfer_id: this.transfer.id});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "调拨物料导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							this.getSheets({material_transfer_id: this.transfer.id,  perPage: 100});
							setTimeout(() => {
								notify.close();
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				ad_export_json_to_excel_multi_sheet([this.table_temp], '调拨物料导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				sheets: [],
				statuses: [],
				suppliers: [],
				warehouses: [],
				status_tabs: [],
				show_fillter: false,
				transfers_drawer: false,
				sheet: {},
				transfer: {},
				transfers_rules: {
					out_warehouse_id: [{ required: true, message: '请选择调出仓库', trigger: 'blur' }],
					in_warehouse_id: [{ required: true, message: '请选择调入仓库', trigger: 'blur' }],
				},
				params: {
					status: [],
					perPage: 10
				}
			}
		},
		async created () {
			this.getSuppliers(this.params, true)
		}
	};
</script>